<template>
	<div class="leading-relaxed tracking-wide">
		<h2 class="text-black font-bold text-xl my-5">المهام والقيم</h2>
		<p class=" font-medium">القيام بأعمال النظافة في كل المبنى بما يحتويه من مرافق رئيسية وفرعية.</p>
		<ul class="w-full md:w-auto my-8">
			<li class="flex items-center my-2 " v-for="(item, index) in data" :key="index">
				<span class="block rounded-md bg-primary h-4 w-4 mx-2"></span>
				<span class="w-11/12">{{ item }}</span>
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	data() {
		return {
			data: [
				'تقديم خدمات السفرجة اليومية طبقاً للمواصفات المتفق عليها.( القهوة- الشاي- الماء- مواد غذائية جافة)',
				'القيام بأعمال المناولة ونقل الأغراض والمستلزمات والأثاث وما شابهه من أي مكان إلى أي مكان يحدده طاقم المؤسسة.',
				'متابعة تنظيف مداخل المبنى الخارجية والداخلية ومواقف السيارات والساحات واالدراج والممرات والمنحدرات الخارجية والحدائق والمناطق الخضراء.',
				'متابعة تنظيف المكاتب والمرافق والدراج والممرات والساحات الداخلية.',
				'متابعة تنظيف جميع القواطع والواجهات والرفوف والحواجز والأبواب الزجاجية، بما في ذلك واجهات المبنى الرئيسية والواجهات في الساحات الخارجية والواجهات الداخلية الزجاجية',
				'متابعة تنظيف جميع القواطع و واجهات الألمنيوم والخشب الموجودة في كل مكان في المبنى على الحيطان والسقف، بما في ذلك الأسقف المستعارة وشرائح السقف المستعارة المصنوعة من الألمنيوم والخشب.',
				'.متابعة تنظيف جميع وحدات الإنارة الداخلية والخارجية بغض النظر عن أماكن تواجدها',
				'متابعة تنظيف التمديدات الكهربائية والميكانيكية الخارجية والتي تتعرض للغبار والأوساخ مثل الأنابيب والداكت الخاص بالتهوية وما شابهها',
				'وضع مواد النظافة ومعط ارت الجو وورق التواليت والتنشيف والصابون في جميع حمامات المبنى والتأكد من توفرها على مدار اليوم.',
				'وضع أكياس النفايات في حاويات النفايات في جميع مرافق المبنى والساحات والشوارع والحدائق وأفنية المبنى الداخلية والخارجية والعمل على تفريغها بانتظام وعلى مدار اليوم ونقلها إلى نقاط التجمع المحددة للتخلص منها من قبل البلدية والأطراف الخارجية التي تختص بإعادة التدوير.',
			],
		};
	},
};
</script>
